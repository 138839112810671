:root {
  --primary-main-rgb: 25, 70, 185;
  --primary-lightest1: #e8ecf8;
  --primary-lighter: #a7bcf3;
  --primary-light: #5f86e9;
  --primary-main: #1946b9;
  --primary-dark: #13348b;
  --primary-darker: #0e2768;
  --bs-primary-rgb: var(--primary-main-rgb);
  --bs-primary: var(--primary-main);
}

.word-break {
  word-break: break-all;
}

.cm-upload-preview-wrapper {
  position: relative;
  max-width: 18rem;
  max-height: 18rem;
  aspect-ratio: 1/1;
  margin: 0 auto;
  padding: 0.8rem;
  border-radius: 1.6rem;
  cursor: pointer;
  overflow: hidden;
}
.cm-upload-preview-wrapper .cm-upload-preview {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  margin: auto;
}
.cm-upload-preview-wrapper .cm-choose-file-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  color: #ffffff;
  z-index: 1;
  font-size: 16px;
  font-weight: 600;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.cm-upload-preview-wrapper .cm-choose-file-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.cm-upload-preview-wrapper:hover .cm-choose-file-wrapper {
  opacity: 1;
  visibility: visible;
}

.cm-u-p-overlay {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 16px;
}

body {
  /*Paper css start*/
  /*Paper css end*/
  /*button css start*/
  /*button css end*/
  /*header css start*/
  /*header css end*/
  /*Breadcrums wrapper css start*/
  /*Breadcrums wrapper css end*/
  /*footer css start*/
  /*footer css end*/
  /*dashbord page css start*/
  /*dashbord page css end*/
  /*ant table css start*/
  /*ant table css end*/
  /*common css start*/
  /*common css end*/
  /*input css start*/
  /*input css end*/
  /*social qr page css start*/
  /*social qr page css end*/
  /*checkout page css start*/
  /*checkout page css end*/
  /*Upgrade Package css start*/
  /*Upgrade Package css end*/
  /*socialQR css start*/
  /*socialQR css end*/
  /*User Payment Page css Start*/
  /*User Payment Page css End*/
  /*common css start*/
  /*common css end*/
}
body .MuiPaper-root {
  border-radius: 1.14286rem;
  background: var(--background-paper);
  box-shadow: 0px 10px 30px 0px rgba(50, 41, 124, 0.05);
}
body .MuiButton-root {
  font-size: 16px;
  border-radius: 6px;
  padding: 8px 16px;
}
body #fuse-toolbar {
  border-radius: 0rem 0rem 1.14286rem 1.14286rem;
}
body .MuiBreadcrumbs-root ol .MuiBreadcrumbs-li a {
  color: initial;
  text-decoration: none;
  font-weight: 500;
}
body .MuiBreadcrumbs-root ol .MuiBreadcrumbs-li a:hover {
  color: var(--primary-main);
}
body .default-checkbox-leftmargin {
  margin-left: -13px;
}
body .buttons-icon-upside {
  border-top: 1px solid;
  padding-top: 15px;
}
body .buttons-icon-upside button span {
  margin-right: 0;
}
body .buttons-icon-upside button:first-child {
  border-right: 1px solid;
  border-radius: 0;
}
body .popup-inner {
  display: flex;
  gap: 15px;
}
@media (max-width: 545px) {
  body .popup-inner {
    flex-direction: column-reverse;
  }
}
body .popup-inner > div {
  flex: 1;
  max-width: 50%;
}
@media (max-width: 545px) {
  body .popup-inner > div {
    max-width: 100%;
  }
}
body .popup-inner > div canvas {
  width: 100%;
}
body .remove-social {
  position: absolute;
  right: -11px;
  background: #fff;
  top: -10px;
}
body #fuse-footer {
  border-radius: 1.14286rem 1.14286rem 0rem 0rem;
}
body .cm-user-profile .profile-thumb {
  max-width: 70px;
  max-height: 70px;
  margin-right: 16px;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}
body .cm-user-profile h5 {
  color: var(--primary-main);
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
}
body .cm-user-profile h6 {
  color: #3a3a3a;
  cursor: pointer;
  font-size: 14px;
}
body .ant-table-wrapper {
  width: 100%;
}
body .ant-table-wrapper .ant-spin-container .ant-table {
  border-radius: 1.14286rem;
  background: var(--background-paper);
  box-shadow: 0px 10px 30px 0px rgba(50, 41, 124, 0.05);
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-total-text {
  letter-spacing: 0.4px;
  color: var(--secondary-contrastText);
  margin: 0 25px;
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-item-link svg {
  color: rgba(0, 0, 0, 0.56);
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-item {
  opacity: 0.7;
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-item a {
  color: var(--secondary-contrastText);
  text-decoration: none;
  font-family: "Roboto";
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-item.ant-pagination-item-active {
  border: none;
  opacity: 1;
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-options {
  order: -1;
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-options .ant-select .ant-select-selector {
  border: none;
}
body .ant-table-wrapper .ant-spin-container .ant-pagination .ant-pagination-options .ant-select.ant-select-focused .ant-select-selector {
  border: none;
  box-shadow: none;
}
body .ant-tag {
  background-color: #111827;
  color: #ffffff;
}
body .ant-btn {
  border: none;
  display: inline-flex;
  align-items: center;
  box-shadow: none;
  gap: 10px;
}
body .ant-btn span {
  color: #111827;
}
body .ant-btn svg {
  margin-right: 0;
}
body .MuiFormControl-root {
  width: 100%;
}
body .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  height: auto;
  line-height: 1;
  padding: 10px;
}
body .MuiInputBase-root .MuiSelect-select {
  height: auto;
  line-height: 1;
  padding: 10px;
}
body .cm-phone-preview-wrapper {
  position: relative;
  height: 600px;
  width: 320px;
}
@media (max-width: 545px) {
  body .cm-phone-preview-wrapper {
    width: 100%;
  }
}
body .cm-phone-preview-wrapper .cm-img-phone-preview {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 290px;
}
body .cm-phone-preview-wrapper .cm-preview-wrapper {
  min-width: 248px;
  max-width: 248px;
  min-height: 480px;
  max-height: 480px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: 50px;
  overflow-y: auto;
  box-shadow: inset 0 0 3px #d5d5d5;
  padding: 0 16px 16px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
body .cm-phone-preview-wrapper .cm-preview-wrapper .MuiPaper-root {
  padding: 10px;
  margin-bottom: 16px;
}
body .cm-phone-preview-wrapper .cm-preview-wrapper .MuiPaper-root.MuiAccordion-root::before {
  display: none;
}
body .cm-phone-preview-wrapper .cm-preview-wrapper .cm-preview-ttl {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.75px;
  padding: 10px 0;
  color: #000;
  /* mix-blend-mode: difference; */
}
body .cm-phone-preview-wrapper .cm-preview-wrapper .cm-social-preview {
  color: var(--primary-main);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
body .cm-phone-preview-wrapper .cm-preview-wrapper .cm-social-preview img {
  max-width: 40px;
}
body .cm-phone-preview-wrapper .cm-preview-wrapper .cm-link-wrapper a {
  color: var(--primary-main);
  display: block;
  text-decoration: none;
}
body .cm-phone-preview-wrapper .cm-preview-wrapper .cm-link-wrapper a:hover {
  opacity: 0.8;
}
body .social-links-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
body .social-links-wrapper img {
  max-width: 30px;
  max-height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
body .cm-web-preview-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  height: 100%;
  background: transparent;
  align-items: center;
}
body .cm-web-preview-wrapper .cm-phone-preview-wrapper {
  width: calc(100% - 20px);
  height: 100%;
}
body .cm-web-preview-wrapper .cm-phone-preview-wrapper > div {
  max-height: -moz-fit-content;
  max-height: fit-content;
}
body .cm-web-preview-wrapper .cm-img-phone-preview {
  display: none;
}
body .cm-web-preview-wrapper .cm-preview-wrapper {
  max-height: 100vh;
  max-width: 650px;
  min-height: calc(100vh - 80px);
  top: unset;
  padding: 16px;
  background-color: #fff;
}
body .cm-web-preview-wrapper .cm-preview-wrapper .logo {
  margin: auto;
}
body .cm-card-wrapper {
  max-height: 50vh;
  overflow-y: auto;
  padding: 10px 20px;
}
body .cm-card-wrapper .MuiListItem-root {
  background-color: var(--background-paper);
  margin: 15px auto;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px 2px var(--divider);
  border: 1px solid var(--divider);
}
body .cm-card-wrapper .MuiListItem-root .MuiListItemButton-root {
  background-color: transparent;
  padding-left: 5px;
}
body .cm-card-wrapper .MuiListItem-root .MuiListItemButton-root .MuiListItemText-root .MuiTypography-root {
  font-size: 1.8rem;
  font-weight: 600;
}
body .cm-card-wrapper .MuiListItem-root .MuiListItemSecondaryAction-root svg {
  color: var(--secondary-main);
}
body .cm-card-wrapper .MuiListItem-root.active {
  box-shadow: 0 0 20px 5px rgba(79, 70, 229, 0.25);
  border-color: var(--secondary-light);
}
body .cm-card-wrapper .MuiListItem-root:first-child {
  margin-top: 0;
}
body .cm-card-wrapper .MuiListItem-root:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 575px) {
  body #AcceptUIContainer {
    margin: 0 auto !important;
    width: 96% !important;
    left: 2% !important;
    top: 20% !important;
    transform: translateY(-20%);
    
  }
  body .cm-web-preview-wrapper .cm-phone-preview-wrapper {
    width: 100%;

}
body .vcard-container {
  padding: 0px;
}
}
body .cm-package-opt button {
  background-color: var(--background-paper);
}
body .cm-package-opt button.active {
  background-color: var(--secondary-main);
  color: var(--primary-contrastText);
}
body .cm-plan-wrapper .MuiChip-root {
  color: var(--primary-contrastText);
}
body .cm-plan-wrapper .MuiButton-root {
  color: var(--primary-contrastText);
}
body .cm-socialQR-wrapper .MuiTabs-root .MuiTabs-scroller .MuiButtonBase-root.Mui-selected.Mui-disabled {
  color: var(--primary-main);
  opacity: 0.8;
  font-weight: 700;
}
body .cm-socialQR-wrapper .MuiAccordion-root {
  margin: 1.5rem 0;
  overflow: hidden;
  border-radius: 1.14286rem;
}
body .cm-socialQR-wrapper .MuiAccordion-root:first-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
body .cm-socialQR-wrapper .MuiAccordion-root:last-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
body .cm-socialQR-wrapper .MuiAccordion-root::before {
  display: none;
}
body .cm-socialQR-wrapper .MuiAccordion-root.Mui-expanded {
  box-shadow: 0 0 8px 4px rgba(204, 204, 204, 0.48);
}
body .cm-socialQR-wrapper .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root {
  background-color: var(--secondary-main);
  min-height: 48px;
}
body .cm-socialQR-wrapper .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 12px 0;
}
body .cm-socialQR-wrapper .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root .MuiAccordionSummary-content .MuiTypography-body1 {
  color: var(--primary-contrastText);
  font-weight: 700;
}
body .cm-socialQR-wrapper .MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root .MuiSvgIcon-root {
  color: var(--primary-contrastText);
}
body .cm-userpayment-head > div {
  padding: 0;
}
body .cm-userpayment-head .cm-hosted-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-decoration: none;
  font-family: "Inter var", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 6px 12px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 54, 90);
  text-transform: none;
  box-shadow: none;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
}
body .cm-userpayment-head .cm-hosted-btn svg {
  margin-right: 8px;
  margin-left: -4px;
}
body .cm-color-picker {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 9;
}
body .max-w-30 {
  max-width: 30px;
}
body .cursor {
  cursor: pointer;
}
body .mvh-38 {
  max-height: 38vh !important;
}
@media screen and (min-width: 960px) {
  body .md-px-20 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
body .btn-small {
  font-size: 12px;
  border-radius: 6px;
  padding: 2px 12px;
  min-height: 30px;
  max-height: 30px;
}
body .ant-table-header table {
  width: -moz-max-content;
  width: max-content;
  min-width: 100%;
}
body .ant-tooltip-inner {
  font-size: 12px;
  max-width: 300px;
}
body .chart-box {
  padding: 6px;
  box-shadow: 0px 4px 14px 0px rgba(36, 29, 83, 0.11);
}
body .url-preview {
  padding: 6px 0px 32px 0;
  color: #518a47;
  font-size: 1.3rem;
  display: block;
  line-height: 1.6;
}/*# sourceMappingURL=app-custom.css.map */