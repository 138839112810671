:root {
    --primary-main-rgb: 25, 70, 185;
    --primary-lightest1: #e8ecf8;
    --primary-lighter: #a7bcf3;
    --primary-light: #5f86e9;
    --primary-main: #1946b9;
    --primary-dark: #13348b;
    --primary-darker: #0e2768;
    --bs-primary-rgb: var(--primary-main-rgb);
    --bs-primary: var(--primary-main);
}


/*common starts*/
.word-break {
    word-break: break-all;
}
.overflow-initial {
    overflow: initial !important;
}

.sticky-preview {
    position: sticky;
    top: 60px;
    max-height: max-content;
}

.buttons-icon-upside button:hover {
    transition: 0.2s ease;
    backdrop-filter: brightness(0.5);
}

.min-w-256-input input {
    min-width: 256px;
}
.min-w-256-input{
    .MuiInputBase-root {
        overflow: hidden;
        button{
            position: absolute;
            right: 0;
            background: #ffffff;
        }
    }
}
.ant-notification {
    z-index: 9999;
}

.snackbar-shorturl {
    position: static;
    margin-top: 10px;
    transform: none;

    .MuiSnackbarContent-message {
        color: #111827;
    }
}
/*common ends*/
.cm-upload-preview-wrapper {
    position: relative;
    max-width: 18rem;
    max-height: 18rem;
    aspect-ratio: 1/1;
    margin: 0 auto;
    padding: 0.8rem;
    border-radius: 1.6rem;
    cursor: pointer;
    overflow: hidden;

    .cm-upload-preview {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        margin: auto;
    }

    .cm-choose-file-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        color: #ffffff;
        z-index: 1;
        font-size: 16px;
        font-weight: 600;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease-in-out;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: #000000;
            opacity: 0.5;
            height: 100%;
            width: 100%;
            z-index: -1;
        }
    }

    &:hover {
        .cm-choose-file-wrapper {
            opacity: 1;
            visibility: visible;
        }
    }
}

.cm-u-p-overlay {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 16px;
}

body {
    /*Paper css start*/
    .MuiPaper-root {
        border-radius: 1.14286rem;
        background: var(--background-paper);
        box-shadow: 0px 10px 30px 0px rgba(50, 41, 124, 0.05);
    }
    /*Paper css end*/

    /*button css start*/
    .MuiButton-root {
        font-size: 16px;
        border-radius: 6px;
        padding: 8px 16px;
    }
    /*button css end*/

    /*header css start*/
    #fuse-toolbar {
        border-radius: 0rem 0rem 1.14286rem 1.14286rem;
    }
    /*header css end*/

    /*Breadcrums wrapper css start*/
    .MuiBreadcrumbs-root {
        ol {
            .MuiBreadcrumbs-li {
                a {
                    color: initial;
                    text-decoration: none;
                    font-weight: 500;

                    &:hover {
                        color: var(--primary-main);
                    }
                }
            }
        }
    }

    .default-checkbox-leftmargin {
        margin-left: -13px;
    }

    .buttons-icon-upside {
        border-top: 1px solid;
        padding-top: 15px;

        button {
            padding: 10px;
            min-height: 55px;
       
            span {
                margin-right: 0;
            }
       
            &:first-child {
                border-right: none !important;
                border-radius: 8px !important;
            }
        }

    }

    .popup-inner {
        display: flex;
        gap: 15px;

        @media (max-width: 545px) {
            flex-direction: column-reverse;
        }

        >div {
            flex: 1;
            max-width: 50%;

            @media (max-width: 545px) {
                max-width: 100%;
            }

            canvas {
                width: 100%;
            }
        }
    }

    .remove-social {
        position: absolute;
        right: -11px;
        background: #fff;
        top: -10px;
    }
    
    /*Breadcrums wrapper css end*/

    /*footer css start*/
    #fuse-footer {
        border-radius: 1.14286rem 1.14286rem 0rem 0rem;
    }
    /*footer css end*/

    /*dashbord page css start*/

    .cm-user-profile {
        .profile-thumb {
            max-width: 70px;
            max-height: 70px;
            margin-right: 16px;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 8px;
        }

        h5 {
            color: var(--primary-main);
            font-size: 22px;
            font-weight: 700;
            text-transform: capitalize;
        }

        h6 {
            color: #3a3a3a;
            cursor: pointer;
            font-size: 14px;
        }
    }
    /*dashbord page css end*/

    /*ant table css start*/
    .ant-table-wrapper {
        width: 100%;

        .ant-spin-container {
            .ant-table {
                border-radius: 1.14286rem;
                background: var(--background-paper);
                box-shadow: 0px 10px 30px 0px rgba(50, 41, 124, 0.05);
            }

            .ant-pagination {
                .ant-pagination-total-text {
                    letter-spacing: 0.4px;
                    color: var(--secondary-contrastText);
                    margin: 0 25px;
                }
                .ant-pagination-item-link {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        color: rgba(0, 0, 0, 0.56);
                    }
                }

                .ant-pagination-item {
                    opacity: 0.7;

                    a {
                        color: var(--secondary-contrastText);
                        text-decoration: none;
                        font-family: "Roboto";
                    }

                    &.ant-pagination-item-active {
                        border: none;
                        opacity: 1;
                    }
                }

                .ant-pagination-options {
                    order: -1;

                    .ant-select {
                        .ant-select-selector {
                            border: none;
                        }

                        &.ant-select-focused {
                            .ant-select-selector {
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
    /*ant table css end*/

    /*common css start*/
    .ant-tag {
        background-color: #111827;
        color: #ffffff;
    }
    .ant-btn {
        border: none;
        display: inline-flex;
        align-items: center;
        box-shadow: none;
        gap: 10px;

        span {
            color: #111827;
        }

        svg {
            margin-right: 0;
        }
    }
    /*common css end*/
    /*input css start*/
    .MuiFormControl-root {
        width: 100%;
        .MuiInputBase-root {
            .MuiInputBase-input {
                height: auto;
                line-height: 1;
                padding: 10px;
            }
        }
    }
    .MuiInputBase-root {
        .MuiSelect-select {
            height: auto;
            line-height: 1;
            padding: 10px;
        }
    }
    /*input css end*/
    /*social qr page css start*/
    .cm-phone-preview-wrapper {
        position: relative;
        height: 600px;
        width: 320px;

        @media (max-width: 545px) {
            width: 100%;
        }

        .cm-img-phone-preview {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            max-width: 290px;
        }

        .cm-preview-wrapper {
            min-width: 248px;
            max-width: 248px;
            min-height: 480px;
            max-height: 480px;
            text-align: center;
            margin: 0 auto;
            position: relative;
            top: 50px;
            overflow-y: auto;
            box-shadow: inset 0 0 3px #d5d5d5;
            padding: 0 16px 16px;
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;

            .MuiPaper-root {
                padding: 10px;
                margin-bottom: 16px;

                &.MuiAccordion-root {
                    &::before {
                        display: none;
                    }
                }
            }

            .cm-preview-ttl {
                font-weight: 500;
                font-size: 16px;
                letter-spacing: 0.75px;
                padding: 10px 0;
                color: #000;
            }

            .cm-social-preview {
                color: var(--primary-main);
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 10px;

                img {
                    max-width: 40px;
                }
            }

            .cm-link-wrapper {
                a {
                    color: var(--primary-main);
                    display: block;
                    text-decoration: none;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .social-links-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        img {
            max-width: 30px;
            max-height: 30px;
            object-fit: contain;
        }
    }

    .cm-web-preview-wrapper {
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        height: 100%;
        background: transparent;
        align-items: center;

        @media (max-width: 545px){
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        .cm-phone-preview-wrapper {
            width: calc(100% - 20px);
            height: 100%;

            >div {
                max-height: fit-content;
            }
        }

        .cm-img-phone-preview {
            display: none;
        }

        .cm-preview-wrapper {
            max-height: 100vh;
            max-width: 650px;
            min-height: calc(100vh - 80px);
            top: unset;
            padding: 16px;
            background-color: #fff;

            .logo {
                margin: auto;
            }
        }
    }
    /*social qr page css end*/

    /*checkout page css start*/
    .cm-card-wrapper {
        max-height: 50vh;
        overflow-y: auto;
        padding: 10px 20px;

        .MuiListItem-root {
            background-color: var(--background-paper);
            margin: 15px auto;
            padding: 10px;
            border-radius: 8px;
            box-shadow: 0 0 10px 2px var(--divider);
            border: 1px solid var(--divider);

            .MuiListItemButton-root {
                background-color: transparent;
                padding-left: 5px;

                .MuiListItemText-root {
                    .MuiTypography-root {
                        font-size: 1.8rem;
                        font-weight: 600;
                    }
                }
            }

            .MuiListItemSecondaryAction-root {
                svg {
                    color: var(--secondary-main);
                }
            }

            &.active {
                box-shadow: 0 0 20px 5px rgba(79, 70, 229, 0.25);
                border-color: var(--secondary-light);
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    @media screen and (max-width: 575px) {
        #AcceptUIContainer {
            margin: 0 auto !important;
            width: 96% !important;
            left: 2% !important;
            top: 20% !important;
            transform: translateY(-20%);
        }
    }
    /*checkout page css end*/

    /*Upgrade Package css start*/
    .cm-package-opt {
        button {
            background-color: var(--background-paper);

            &.active {
                background-color: var(--secondary-main);
                color: var(--primary-contrastText);
            }
        }
    }

    .cm-plan-wrapper {
        .MuiChip-root {
            color: var(--primary-contrastText);
        }

        .MuiButton-root {
            color: var(--primary-contrastText);
        }
    }
    /*Upgrade Package css end*/

    /*socialQR css start*/

    .cm-socialQR-wrapper {
        .MuiTabs-root {
            .MuiTabs-scroller {
                .MuiButtonBase-root {
                    &.Mui-selected {
                        &.Mui-disabled {
                            color: var(--primary-main);
                            opacity: 0.8;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .MuiAccordion-root {
            margin: 1.5rem 0;
            overflow: hidden;
            border-radius: 1.14286rem;

            &:first-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            &:last-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &::before {
                display: none;
            }

            &.Mui-expanded {
                box-shadow: 0 0 8px 4px rgb(204 204 204 / 48%);

                .MuiAccordionSummary-root {
                    background-color: var(--secondary-main);
                    min-height: 48px;

                    .MuiAccordionSummary-content {
                        margin: 12px 0;

                        .MuiTypography-body1 {
                            color: var(--primary-contrastText);
                            font-weight: 700;
                        }
                    }

                    .MuiSvgIcon-root {
                        color: var(--primary-contrastText);
                    }
                }
            }
        }
        .MuiAccordion-root.social-icons-inner{
            overflow: initial;
        }
    }

    /*socialQR css end*/

    /*User Payment Page css Start*/
    .cm-userpayment-head {
        > div {
            padding: 0;
        }

        .cm-hosted-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            outline: 0px;
            border: 0px;
            margin: 0px;
            cursor: pointer;
            user-select: none;
            vertical-align: middle;
            appearance: none;
            text-decoration: none;
            font-family: "Inter var", Roboto, Helvetica, Arial, sans-serif;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.75;
            min-width: 64px;
            padding: 6px 12px;
            border-radius: 8px;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: rgb(255, 255, 255);
            background-color: rgb(0, 54, 90);
            text-transform: none;
            box-shadow: none;
            height: 40px;
            min-height: 40px;
            max-height: 40px;

            svg {
                margin-right: 8px;
                margin-left: -4px;
            }
        }
    }
    /*User Payment Page css End*/
    /*common css start*/
    .cm-color-picker {
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 9;
    }

    .max-w-30 {
        max-width: 30px;
    }

    .cursor {
        cursor: pointer;
    }

    .mvh-38 {
        max-height: 38vh !important;
    }

    @media screen and (min-width: 960px) {
        .md-px-20 {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .btn-small{
        font-size: 12px;
        border-radius: 6px;
        padding: 2px 12px;
        min-height: 30px;
        max-height: 30px;
    }

    .ant-table-header table {
        width: max-content;
        min-width: 100%;
    }

    .ant-tooltip-inner {
        font-size: 12px;
        max-width: 300px;
    }
    
    .chart-box{
        padding: 6px;
        box-shadow: 0px 4px 14px 0px rgb(36 29 83 / 11%);
    }

    .url-preview{
        padding: 6px 0px 32px 0;
        color: #518a47;
        font-size: 1.3rem;
        display: block;
        line-height: 1.6;
      }
    
      .vcard-container {
        padding: 16px;
        background: #f1f3f4;
        border-radius: 25px;
        width: 100%;
        color: #fff;
        position: relative;
        max-height: 600px;
        overflow-y: auto;
        box-shadow: 0 2px 3px #c9c9c9;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media (max-width: 575px){
            border-radius: 0px;
        }
      }

      .vcard-loading {
        width: 100%;
        height: 100%;
      }

      .vcard-header {
        padding: 16px;
        text-align: center;
        border-radius: 10px 10px 0 0;
        color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width: 575px){
            border-radius: 0;
        }
      }

      .vcard-profile-pic {
        border-radius: 50%;
        margin-bottom: 10px;
        height: 100px;
      }

      .vcard-buttons {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .vcard-button {
        flex: 1;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
      }

      .vcard-info-section {
        border-radius: 0 0 10px 10px;
        height: 100%;
      }

      .vcard-info-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }

      .vcard-social-media {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media(max-width:575px){
            max-width:80%;
        }
      }

      .vcard-fab {
        position: absolute;
        bottom: 15px;
        right: 15px;
        color: rgba(0, 0, 0, 0.6);
      }
      .cm-error {
        input {
            border-color:  red !important;
            &:focus{
                box-shadow: 0 0 0 1px red !important;
            }

        }

      }   
      .mobile-full{
        .form-control{
            width: 100%;
        }
      }

    /*common css end*/
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 2px !important;
    bottom: 0;
    padding: 0;
    border-radius: 3px 0 0 3px;
    border: 0 !important;
    height: 87%;
    left: 2px;
}

.qr-frame {
    padding: 20px;
    margin-top: 20px;
    display: inline-block;
    border: 10px solid #FFFC00; /* Snapchat yellow color */
    border-radius: 30px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4); /* Shadow for depth */
    position: relative; /* For positioning elements inside */
    background-color: #FFFFFF; /* White background for contrast */
}

/* Optional: Create a playful element like a shadow */
.qr-frame::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-radius: 30px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2), transparent 70%); /* Light shadow effect */
    z-index: -1; /* Position behind the QR code */
}
