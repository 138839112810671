
/*@tailwind base;*/

/**
 * Custom base styles
 */

* {
    /* Text rendering */
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

* :focus {
     outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #ffffff;
}

body {
    font-size: 14px;
    line-height: 1.4;
    overflow-x: hidden;
    font-feature-settings: "salt";
}

html, body {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

html, body {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    min-height: 100%;
    width: 100%;
    flex: 1 1 auto;
}

#root {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 9999;
}

.MuiModal-root {
    z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 16px;
        height: 16px;
        transition: all 0.3s ease-in-out;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 20px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
    transition: none !important;
    animation: none !important;
}

button:focus {
    outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
    transitionDelay: 9999s;
    transitionProperty: background-color, color;
}

:focus {
    outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
    height: 1px !important;
}
